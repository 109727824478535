import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { FaceDetection } from '@mediapipe/face_detection';
import * as cameraUtils from '@mediapipe/camera_utils';

const videoWidth = 1280;
const videoHeight = 720;

export const useFaceDetection = () => {
  const webcamRef = useRef<Webcam>(null);
  const cameraRef = useRef<cameraUtils.Camera | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const handleStartFaceDetection = useCallback(
    (mediaSrc: HTMLVideoElement) => {
      const faceDetection = new FaceDetection({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@0.4/${file}`;
        },
      });
      faceDetection.setOptions({
        model: 'short',
        minDetectionConfidence: 0.9,
      });
      faceDetection.onResults((results) => {
        setIsFaceDetected(results.detections.length > 0);
      });

      cameraRef.current = new cameraUtils.Camera(mediaSrc, {
        onFrame: async () => {
          await faceDetection.send({ image: mediaSrc });
          if (isLoading) setIsLoading(false);
        },
        width: videoWidth,
        height: videoHeight,
      });
      cameraRef.current.start();
    },
    [isLoading],
  );

  const handleStopFaceDetection = useCallback(() => {
    if (cameraRef.current) {
      cameraRef.current.stop();
    }
  }, []);

  useEffect(() => {
    if (webcamRef.current && webcamRef.current.video && isVideoReady) {
      handleStartFaceDetection(webcamRef.current.video);
    }

    return () => handleStopFaceDetection();
  }, [handleStartFaceDetection, handleStopFaceDetection, isVideoReady]);

  return {
    webcamRef,
    isFaceDetected,
    isLoading,
    setIsVideoReady,
    videoWidth: videoWidth,
    videoHeight: videoHeight,
  };
};

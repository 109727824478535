import jwt_decode from 'jwt-decode';
import { getMs } from './utils';
import { differenceInSeconds } from 'date-fns';
import { sifeEndpoints } from '../API/sifeEndpoints';
import logout from './logout';
import { trackedInterval } from './time.utils';
import {
  globalSessionWillExpireDialogVar,
  globalSnackbarVar,
  userVar,
} from '../cache.reactiveVars';
export const TOKEN_CHECK_TIME = getMs({ s: 1 });
export const DEFAULT_REMAINING_TIME = getMs({ s: 10 });
const TIME_BEFORE_TOKEN_EXPIRATION = getMs({ s: 30 });

const getTokenExpiration = () => {
  if (!localStorage.token) return Date.now();
  // @ts-ignore
  const { exp } = jwt_decode(localStorage.token);
  const tokenExpParsed = exp * getMs({ s: 1 });
  const tokenWithSecondsMargin = tokenExpParsed - TIME_BEFORE_TOKEN_EXPIRATION;
  return tokenWithSecondsMargin;
};

function isTokenValid() {
  if (!localStorage.token) return false;
  const currentTime = getTokenExpiration();
  return !(Date.now() >= currentTime);
}

export function getTokenRemainigTime() {
  const tokenExpiration = getTokenExpiration();
  const remainingTime = differenceInSeconds(tokenExpiration, Date.now());
  return remainingTime;
}

export function checkToken(ownInterval) {
  try {
    if (localStorage?.token && !isTokenValid()) {
      signOut(ownInterval);
    }
    const remainingTime = getTokenRemainigTime();

    if (
      Boolean(remainingTime) &&
      remainingTime <= TIME_BEFORE_TOKEN_EXPIRATION / 1000
    ) {
      globalSessionWillExpireDialogVar({
        open: true,
        acceptLabel: 'Mantener sesión activa',
        cancelLabel: 'Cerrar sesión',
        closeOnBackdropClick: false,
        closeOnEscKey: false,
        title: 'Has excedido el tiempo de sesión.',
        content: 'Por tu seguridad cerraremos tu sesión.',
        onClose: (renewToken) => {},
        footer: '',
        loading: false,
        loadingMessage: '',
        icon: undefined,
        emoji: '',
      });
    }
  } catch (error) {
    console.log(error);
    clearInterval(ownInterval);
    startTokenInterval();
  }
}

export function startTokenInterval() {
  const checkTokenInt = trackedInterval(
    () => checkToken(checkTokenInt),
    TOKEN_CHECK_TIME,
  );
}

export const handleRenewTokenSIFE = async () => {
  try {
    const response = await sifeEndpoints.renewToken.post(
      {},
      {
        headers: {
          Authorization: localStorage.token_sife,
        },
      },
    );

    if (response.status !== 200) {
      throw new Error(response?.data?.errors[0]?.type);
    }

    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleRenewToken = async (newToken) => {
  localStorage.token = `Bearer ${newToken}`;
  const tokenSife = await handleRenewTokenSIFE();
  const userData = userVar();

  userData.token = { token: tokenSife?.token, exp: tokenSife?.exp };
  localStorage.token_sife = tokenSife.token;
  localStorage.user = JSON.stringify(userData);

  userVar(userData);
};

export function signOut(interval) {
  if (interval) {
    clearInterval(interval);
  }
  logout();
  globalSnackbarVar({
    show: true,
    message: 'Tu sesión ha expirado, vuelve a iniciar sesión',
    severity: 'warning',
  });
}

import { Box, Stack, DialogContent, DialogActions } from '@mui/material';
import { Alert, AlertTitle, Button, Typography } from '../../../newComponents';
import { setVideoAgreementRecorderDialogVar } from './VideoAgreementRecorderDialog.vars';
import { Icon } from '../../../components/Icons/Icons';
import { useScreenSize } from '../../../Hooks';

export const VideoAgreementRecorderInstructions = () => {
  const { isMobile } = useScreenSize();

  return (
    <>
      <DialogContent
        sx={{ minHeight: '70vh', display: 'flex', justifyContent: 'center' }}
      >
        <Stack justifyContent="center" alignItems="center" spacing={3}>
          <Typography variant="h3" align="center">
            Instrucciones
          </Typography>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            alignItems="center"
          >
            <Stack>
              <Icon icon="emotion_line" />
              <Typography variant="body1" align="center" maxWidth="300px">
                Tu rostro debe estar bien iluminado y centrado en la pantalla.
              </Typography>
            </Stack>
            <Stack>
              <Icon icon="mic_2_line" />
              <Typography variant="body1" align="center" maxWidth="300px">
                Lee fuerte y claro todo el texto.
              </Typography>
            </Stack>
            <Stack>
              <Icon icon="repeat_2_line" />
              <Typography variant="body1" align="center" maxWidth="300px">
                Revisa la grabación y, si es necesario, grábalo nuevamente.
              </Typography>
            </Stack>
          </Stack>
          <Alert severity="warning">
            <AlertTitle>Importante</AlertTitle>
            Debes otorgar los permisos para poder usar tu micrófono y cámara.
          </Alert>
          {!isMobile && (
            <Box>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  setVideoAgreementRecorderDialogVar({ step: 2 });
                }}
              >
                Continuar
              </Button>
            </Box>
          )}
        </Stack>
      </DialogContent>
      {isMobile && (
        <DialogActions sx={{ padding: '16px' }}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              setVideoAgreementRecorderDialogVar({ step: 2 });
            }}
          >
            Continuar
          </Button>
        </DialogActions>
      )}
    </>
  );
};

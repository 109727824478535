import { useEffect, useRef, useState } from 'react';

export function useStartVideoRecorderCountdown() {
  const [countDown, setCountDown] = useState<number | null>(null);
  const onCountDownEndRef = useRef<() => void>();

  const handleStartCountDown = ({
    onCountDownEnd,
  }: {
    onCountDownEnd: () => void;
  }) => {
    setCountDown(3);
    onCountDownEndRef.current = onCountDownEnd;
  };

  useEffect(() => {
    if (countDown === null) return;
    if (countDown === 0) {
      setCountDown(null);
      onCountDownEndRef.current();
      return;
    }

    const timer = setTimeout(() => {
      setCountDown((prev) => prev - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [countDown]);

  return {
    countDown,
    handleStartCountDown,
  };
}

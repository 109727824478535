import React from 'react';
import {
  UserProfileCard,
  UserProfileCardAvatar,
  UserProfileCardHeader,
} from '../EmployeeInfo.styles';
import { getBannerColorAccordingEmployeeState } from './EmployeeInfoHeader.helpers';
import { Avatar, Box, Stack } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_USER } from '../../Employees.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { loggerUtil } from '../../../../../utils/loggerUtil';
import { SendOnboardingNotifDialog } from '../SendOnboardingNotifDialog/SendOnboardingNotifDialog';
import {
  GET_ONBOARDING_LINK,
  SEND_ONBOARDING_NOTIFICATION,
} from '../EmployeeInfo.gql';
import { handleSendOnboardingNotifResponse } from '../SendOnboardingNotifDialog/SendOnboardingNotifDialog.helpers';
import {
  sendOnboardingNotifDialogVar,
  sendRecoveryDialogVar,
} from '../../Employees.vars';
import { changeEmployeeLegalStatusDialogVar } from '../../Dialogs/ChangeEmployeeLegalStatusDialog/ChangeEmployeeLegalStatusDialog.vars';
import {
  changeEmployeeStatusDialogVar,
  resetChangeEmployeeStatusDialogVar,
} from '../../Dialogs/ChangeEmployeeStatusDialog/ChangeEmployeeStatusDialog.vars';
import { EmployeeInfoTabs } from '../EmployeeInfoTabs';
import { useDownloads } from '../../../../../Hooks/useDownloads';
import { emptyData } from '../EmployeeInfo.constants';
import {
  downloadEmployeeRecordsDialogVar,
  resetDownloadEmployeeRecordsDialogVar,
} from '../../Dialogs/DownloadEmployeeRecordsDialog/DownloadEmployeeRecordsDialog.vars';
import { Typography } from '../../../../../newComponents/Typography';
import { EmployeeStatusChip } from '../../../../../businessComponents/EmployeeStatusChip/EmployeeStatusChip';
import { EmployeeInfoHeaderOnboardingAlert } from './EmployeeInfoHeaderOnboardingAlert';
import { EmployeeActionsButton } from '../../../../../businessComponents/EmployeeActionsButton/EmployeeActionsButton';
import { useSendEmployeePendingSignsNotification } from '../../../../Settings/Preferences/Notifications/Notifications.hooks';
import { useScreenSize } from '../../../../../Hooks';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  createEmployeeAbsenceDialogVar,
  finishEmployeeAbsenceDialogVar,
} from '../../../Absence/EmployeeAbsence.vars';
import { EMPLOYEE_ROW } from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';
import { formatDate } from '../../../../../utils/utils';
import { copyToClipboard } from '../../../../Landing/Development/helpers';

export function EmployeeInfoHeader({
  employeeData,
  theme,
  employeeId,
  refetchCurrentUser,
  handlePrintLetter,
  userPermissions,
  tabValue,
  setTabValue,
  hideCreditsSection,
  handleClose,
}) {
  const { sendNotificationToSingleUser } =
    useSendEmployeePendingSignsNotification();
  const {
    currentStatus,
    currentLegalStatus,
    workCenterId,
    email,
    userId,
    currentAbsence,
  } = employeeData;
  const actionType = currentStatus?.name === 'BAJA' ? 'ALTA' : 'BAJA';
  const [updateUser] = useMutation(UPDATE_USER, {
    update: (cache) => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'allUsers',
      });
    },
  });

  const [sendOnboardingNotif] = useMutation(SEND_ONBOARDING_NOTIFICATION);
  const [getOnboardingLink] = useLazyQuery(GET_ONBOARDING_LINK, {
    fetchPolicy: 'network-only',
  });

  const { handleAddDownload } = useDownloads();

  const handleChangeStatus = ({ actionType: actionTypeProvided }) => {
    changeEmployeeStatusDialogVar({
      open: true,
      employeeId,
      actionType: actionTypeProvided ? actionTypeProvided : actionType,
      workCenterId,
      onCancel: () => {
        resetChangeEmployeeStatusDialogVar();
      },
      onConfirm: () => {
        handleClose();
      },
    });
  };

  const handleChangeLegalStatus = () => {
    changeEmployeeLegalStatusDialogVar({
      open: true,
      employeeId,
      legalStatus: currentLegalStatus,
      refetchFunc: refetchCurrentUser,
    });
  };

  const handleSendRecovery = ({ employeeId }) => {
    sendRecoveryDialogVar({
      ...sendRecoveryDialogVar(),
      open: true,
      employeeId,
    });
  };

  const handleChangeBlockUserAccess = async ({ employeeId, blockEmployee }) => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Actualizando datos',
        clickable: false,
      });
      await updateUser({
        variables: {
          input: {
            userId: employeeId,
            blockEmployeeOnBaja: blockEmployee,
          },
        },
      });
      globalSnackbarVar({
        show: true,
        message: 'Empleado actualizado correctamente',
        severity: 'success',
      });
    } catch (e) {
      loggerUtil.error(e);
      globalSnackbarVar({
        show: true,
        message: 'Hubo en error al actualizar los datos. Contacta a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  const handleSendOnboardingNotif = async () => {
    if (!employeeData?.email) {
      return sendOnboardingNotifDialogVar({
        open: true,
        employeeId: employeeData?.userId,
        refetchFunc: refetchCurrentUser,
      });
    }

    try {
      globalBackdropVar({
        open: true,
        text: 'Enviando notifiación de onboarding...',
      });
      const employeeId = employeeData.userId;
      const sendRes = await sendOnboardingNotif({
        variables: {
          input: {
            employeeId,
          },
        },
      });
      handleSendOnboardingNotifResponse({
        response: sendRes.data,
        refetchFunc: refetchCurrentUser,
      });
    } catch (e) {
      loggerUtil.error(e);
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          'Ocurrio un problema con tu petición, intenta de nuevo o contacta a soporte.',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleDownloadEmployeeRecords = async ({ employeeId, employeeRfc }) => {
    downloadEmployeeRecordsDialogVar({
      open: true,
      employeeId,
      employeeRfc,
      onConfirm: () => {
        handleAddDownload({
          download: {
            name: `expediente-${employeeRfc}`,
            status: 'pending',
            action: 'downloadEmployeeRecords',
            message: '',
            payload: { userId: employeeId, rfc: employeeRfc },
          },
          onFails: () => {
            resetDownloadEmployeeRecordsDialogVar();
          },
          onAdded: () => {
            employeeData = emptyData;
          },
        });
        resetDownloadEmployeeRecordsDialogVar();
      },
      onCancel: () => {
        resetDownloadEmployeeRecordsDialogVar();
      },
    });
  };

  const handleOpenAbsentDialog = () => {
    if (!employeeData) return;
    if (currentStatus?.name !== 'ACTIVE') {
      return globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'El empleado debe estar activo para crear una ausencia.',
        duration: 4000,
      });
    }

    if (employeeData?.currentAbsence?.name === 'PENDING') {
      const absenceId = employeeData?.currentAbsence?.absenceId;
      if (!absenceId) {
        return globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'No se pudo obtener el id de la ausencia.',
          duration: 4000,
        });
      }
      finishEmployeeAbsenceDialogVar({
        open: true,
        employeeId: employeeData.userId,
        absenceIds: [absenceId],
        isMultiple: false,
      });
      return;
    }

    createEmployeeAbsenceDialogVar({
      open: true,
      employee: { ...employeeData, type: EMPLOYEE_ROW },
      autoCompleteEmployee: true,
    });
  };

  const handleGetOnboardingLink = async (userId) => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Generando link de onboarding...',
      });
      const employeeId = employeeData.userId;
      const { data } = await getOnboardingLink({
        variables: {
          input: {
            employeeId,
          },
        },
      });

      if (data?.getOnboardingLink.__typename === 'GetOnboardingLinkSuccess') {
        const {
          getOnboardingLink: { onboardingLink },
        } = data;
        copyToClipboard(onboardingLink, 'Link de onboarding copiado');
      } else {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message:
            data?.getOnboardingLink?.message ||
            'Ocurrio un problema al obtener el link de onboarding.',
        });
      }
    } catch (e) {
      loggerUtil.error(e);
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          'Ocurrio un problema con tu petición, intenta de nuevo o contacta a soporte.',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const showSendOnboardingAlert =
    currentStatus?.name === 'PREREGISTERED' &&
    employeeData?.alta === 'SIFE' &&
    currentLegalStatus?.name !== 'SUING';

  const { isMobile } = useScreenSize();

  return (
    <>
      <EmployeeInfoHeaderOnboardingAlert
        showOnboardingAlert={showSendOnboardingAlert}
        sentOnboardingNotification={employeeData.sentOnboardingNotification}
        handleSendOnboardingNotif={handleSendOnboardingNotif}
      />
      <UserProfileCard>
        <UserProfileCardHeader
          sx={{
            background: getBannerColorAccordingEmployeeState({
              status: currentStatus?.name,
              palette: theme.newPalette,
              legalStatus: currentLegalStatus,
              absenceStatus: employeeData.currentAbsence,
            }),
          }}
          gap={2}
        >
          <UserProfileCardAvatar>
            <Avatar
              alt={employeeData?.fullName}
              src={employeeData?.profilePicture}
            />
          </UserProfileCardAvatar>
          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems={isMobile ? 'center' : 'center'}
            gap="8px"
            width="100%"
            position="relative"
          >
            <Stack alignItems={isMobile ? 'center' : 'flex-start'} gap="4px">
              <Typography
                variant={isMobile ? 'body1' : 'h4'}
                color="inherit"
                align={isMobile ? 'center' : 'left'}
              >
                {employeeData.fullName}
              </Typography>
              <Typography
                variant={isMobile ? 'caption' : 'body2'}
                color="inherit"
                align={isMobile ? 'center' : 'left'}
              >
                {employeeData.workTitle}
              </Typography>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                gap={2}
              >
                <EmployeeStatusChip
                  status={currentStatus?.name}
                  employeeBlocked={employeeData.blocked}
                  allWhite
                />
                {currentLegalStatus?.name === 'SUING' && (
                  <EmployeeStatusChip
                    status={currentLegalStatus?.name}
                    employeeBlocked={employeeData.blocked}
                    variant="filled"
                    allWhite
                  />
                )}

                {currentAbsence?.name === 'PENDING' && (
                  <EmployeeStatusChip
                    status={currentAbsence?.name}
                    employeeBlocked={employeeData.blocked}
                    label={`Ausente por ${
                      currentAbsence?.reason
                    } del ${formatDate(currentAbsence?.start)} al ${formatDate(
                      currentAbsence?.end,
                    )}`}
                    allWhite
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          {isMobile && (
            <Box sx={{ position: 'absolute', right: '21px', top: '21px' }}>
              <EmployeeActionsButton
                userPermissions={userPermissions}
                alta={employeeData.alta}
                currentStatus={employeeData.currentStatus}
                currentLegalStatus={employeeData.currentLegalStatus}
                currentAbsenceStatus={employeeData.currentAbsence}
                userId={employeeData.userId}
                employeeRfc={employeeData.rfc}
                workSpaceId={employeeData._id}
                workCenterId={employeeData.workCenterId}
                employeeBlocked={employeeData.blocked}
                handlePrintLetter={handlePrintLetter}
                handleChangeStatus={handleChangeStatus}
                handleSendRecovery={handleSendRecovery}
                handleChangeLegalStatus={handleChangeLegalStatus}
                handleDownloadEmployeeRecords={handleDownloadEmployeeRecords}
                handleSendNotificationToSingleUser={() =>
                  sendNotificationToSingleUser(userId, email)
                }
                handleChangeBlockUserAccess={handleChangeBlockUserAccess}
                handleOpenAbsentDialog={handleOpenAbsentDialog}
                employeeData={employeeData}
                handleGetOnboardingLink={handleGetOnboardingLink}
              />
            </Box>
          )}
          {isMobile && (
            <Box
              sx={{ position: 'absolute', left: '21px', top: '21px' }}
              onClick={handleClose}
            >
              <Icon icon="arrow_left_s_line" pointer />
            </Box>
          )}
        </UserProfileCardHeader>
        <Stack
          flexDirection="row"
          gap="12px"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: '8px',
            paddingLeft: '8px',
          }}
        >
          <EmployeeInfoTabs
            tabValue={tabValue}
            setTabValue={setTabValue}
            hideCreditsSection={hideCreditsSection}
          />
          {!isMobile && (
            <Box maxWidth="36px">
              <EmployeeActionsButton
                userPermissions={userPermissions}
                alta={employeeData.alta}
                currentStatus={employeeData.currentStatus}
                currentLegalStatus={employeeData.currentLegalStatus}
                currentAbsenceStatus={employeeData.currentAbsence}
                userId={employeeData.userId}
                employeeRfc={employeeData.rfc}
                workSpaceId={employeeData._id}
                workCenterId={employeeData.workCenterId}
                employeeBlocked={employeeData.blocked}
                handlePrintLetter={handlePrintLetter}
                handleChangeStatus={handleChangeStatus}
                handleSendRecovery={handleSendRecovery}
                handleChangeLegalStatus={handleChangeLegalStatus}
                handleDownloadEmployeeRecords={handleDownloadEmployeeRecords}
                handleSendNotificationToSingleUser={() =>
                  sendNotificationToSingleUser(userId, email)
                }
                handleChangeBlockUserAccess={handleChangeBlockUserAccess}
                handleOpenAbsentDialog={handleOpenAbsentDialog}
                employeeData={employeeData}
                handleGetOnboardingLink={handleGetOnboardingLink}
              />
            </Box>
          )}
        </Stack>
      </UserProfileCard>
      <SendOnboardingNotifDialog />
    </>
  );
}

import { styled } from '@mui/material';
import { IconButton } from '../../../../newComponents/IconButton';

export const OptionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.newPalette.grey.grey700,
  ':hover': { backgroundColor: theme.newPalette.grey.grey700 },
}));

export const VideoPlayerContainer = styled('div')(() => ({
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  zIndex: 2,
  backgroundColor: 'black',
  overflow: 'hidden',
}));

export const VideoWrapper = styled('div')(() => ({
  display: 'flex',
  backgroundColor: '#000000',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '100%',
  maxHeight: '100%',
}));

export const Video = styled('video')(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '5px',
  objectFit: 'cover',
}));

export const VideoActionWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bottom: '1rem',
  zIndex: theme.zIndex.modal + 1,
}));

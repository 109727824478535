import { useEffect } from 'react';
import { Container, Stack } from '@mui/material';
import { Accordion } from './Accordion';
import { EmployeeRecordTagsCatalog } from '../../../Settings/Preferences/EmployeeRecordTagsCatalog';
import { Permissions } from './Permissions/Permissions';
import { CreateTemplateContainer } from '../../../RHPod/Documents/Templates/CreateTemplateContainer';
import { WorkTitles } from '../../../Settings/Preferences/WorkTitles/WorkTitles';
import { RecordChecklist } from '../../../Settings/Preferences/RecordChecklist/RecordChecklist';
import { Grupos } from '../../../Settings/Preferences/Groups/Groups';
import { Billing } from './Billing/Billing';
import { Notifications } from '../../../Settings/Preferences/Notifications/Notifications';
import { Calendar } from '../../../Settings/Preferences/Calendar/Calendar';
import { Holidays } from '../../../Settings/Preferences/Holidays/Holidays';
// import { VideoAgreementTemplates } from '../../../Settings/Preferences/VideoAgreementTemplates';
import { useGetCompanyModules } from './Hooks/useGetCompanyModules';
import { useCompanyModules } from '../../../../Hooks/useCompanyModules';

export const Preferences = () => {
  const { handleGetCompanyModules } = useGetCompanyModules();
  const {
    holidays: { active: isHolidaysModuleActive },
  } = useCompanyModules();

  useEffect(() => {
    handleGetCompanyModules();
  }, [handleGetCompanyModules]);

  return (
    <Container maxWidth="xl" disableGutters>
      <Stack gap={2} mt={2} p={{ xs: 0, md: 5 }}>
        <Accordion icon="folders_line" label="Catálogo de documentos">
          <EmployeeRecordTagsCatalog />
        </Accordion>
        <Accordion icon="file_text_line" label="Plantillas">
          <CreateTemplateContainer />
        </Accordion>
        <Accordion icon="survey_line" label="Expedientes laborales">
          <RecordChecklist />
        </Accordion>
        <Accordion icon="suitcase_line" label="Puestos de trabajo">
          <WorkTitles />
        </Accordion>
        <Accordion icon="team_line" label="Grupos">
          <Grupos />
        </Accordion>
        {isHolidaysModuleActive && (
          <>
            <Accordion icon="calendar_line" label="Calendario" chip>
              <Calendar />
            </Accordion>
            <Accordion icon="sun_foggy_line" label="Vacaciones" chip>
              <Holidays />
            </Accordion>
          </>
        )}
        <Accordion icon="notification_line" label="Notificaciones">
          <Notifications />
        </Accordion>
        <Accordion
          icon="quill_pen_line"
          label="Permisos de firmado y verificación de identidad"
        >
          <Permissions value="signingPermissions" />
        </Accordion>
        {/* <Accordion icon="vidicon_line" label="Video acuerdo">
          <VideoAgreementTemplates />
        </Accordion> */}
        <Accordion icon="admin_line" label="Permisos a empleados">
          <Permissions value="employeePermissions" />
        </Accordion>
        <Accordion icon="file_chart_line" label="Facturación">
          <Billing />
        </Accordion>
      </Stack>
    </Container>
  );
};

import { Box, Stack, DialogContent, DialogActions } from '@mui/material';
import { Button, Typography } from '../../../newComponents';
import { VideoAgreementCameraIcon } from './VideoAgreementRecorderDialogAssets/VideoAgreementCameraIcon';
import { setVideoAgreementRecorderDialogVar } from './VideoAgreementRecorderDialog.vars';
import { useScreenSize } from '../../../Hooks';

export const VideoAgreementRecorderInfo = () => {
  const { isMobile } = useScreenSize();

  return (
    <>
      <DialogContent
        sx={{ minHeight: '70vh', display: 'flex', justifyContent: 'center' }}
      >
        <Stack justifyContent="center" alignItems="center" spacing={3}>
          <VideoAgreementCameraIcon
            width={isMobile ? 150 : 240}
            height={isMobile ? 150 : 240}
          />
          <Typography variant="h3" align="center">
            Graba tu video de acuerdo
          </Typography>
          <Typography
            variant="body1"
            align="center"
            maxWidth="450px"
            color="grey"
          >
            A continuación, deberás grabar un video leyendo el texto que
            aparecerá en la pantalla.
          </Typography>
          {!isMobile && (
            <Box>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  setVideoAgreementRecorderDialogVar({ step: 1 });
                }}
              >
                Continuar
              </Button>
            </Box>
          )}
        </Stack>
      </DialogContent>
      {isMobile && (
        <DialogActions sx={{ padding: '16px' }}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              setVideoAgreementRecorderDialogVar({ step: 1 });
            }}
          >
            Continuar
          </Button>
        </DialogActions>
      )}
    </>
  );
};

/**
 * Utils for time related functions such as intervals and timeouts
 **/

export function trackedInterval(callback, delay, ...args) {
  const intervalId = setInterval(callback, delay, ...args);

  // @ts-ignore
  window.intervalsList.push({
    id: intervalId,
    callback: callback,
    delay: delay,
    args: args,
  });
  return intervalId;
}

export function clearTrackedInterval(intervalId) {
  // @ts-ignore
  window.intervalsList = window.intervalsList.filter(
    (interval) => interval.id !== intervalId,
  );
  clearInterval(intervalId);
}

export function clearAllTrackedIntervals() {
  // @ts-ignore
  window.intervalsList?.forEach((interval) => clearInterval(interval.id));
  // @ts-ignore
  window.intervalsList = [];
}

/**
 * @param {object} input
 * @param {string} input.givenDateString - UTC time string
 * @param {number} input.hours
 * @returns {{ hasPassed: boolean, remainingHours: number, remainingMin: number, targetDateTime: string }}
 */
export function hasHoursPassedWithRemainingTime({ givenDateString, hours }) {
  const givenDate = new Date(givenDateString); // Parses as UTC
  const currentDate = new Date(); // Local time

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = currentDate.getTime() - givenDate.getTime();

  // Convert milliseconds to hours
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

  // Check if the difference is more than the specified number of hours
  let hasPassed = differenceInHours > hours;

  // Calculate remaining time
  const remainingHours = Math.max(hours - differenceInHours, 0);
  const remainingHoursWhole = Math.floor(remainingHours);
  const remainingMinutes = Math.floor(
    (remainingHours - remainingHoursWhole) * 60,
  );

  // Calculate target date and time when the 24-hour period will pass in the user's local timezone
  const targetDate = new Date(givenDate.getTime() + hours * 60 * 60 * 1000);
  const targetDateTime = targetDate.toLocaleDateString('es-MX', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  if (!givenDateString) {
    hasPassed = true;
  }

  return {
    hasPassed,
    remainingHours: remainingHoursWhole,
    remainingMin: remainingMinutes,
    targetDateTime,
  };
}

export function debounceInput(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Función para normalizar fecha a medianoche en UTC
/** @param {Date} date */
export function normalizeDateToUTC(date) {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  return new Date(Date.UTC(year, month, day));
}

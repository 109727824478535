import { Stack } from '@mui/material';
import { Alert } from '../../../../newComponents/Alert';

type VideoAgreementRecorderAlertsProps = {
  isFaceDetected: boolean;
  isVideoRecording: boolean;
  isRecordingRestarted: boolean;
  setIsRecordingRestarted: (value: boolean) => void;
};

export const VideoAgreementRecorderAlerts = (
  props: VideoAgreementRecorderAlertsProps,
) => {
  const {
    isFaceDetected,
    isVideoRecording,
    isRecordingRestarted,
    setIsRecordingRestarted,
  } = props;

  const showStartRecordingAlert =
    isFaceDetected && !isVideoRecording && !isRecordingRestarted;
  const showFaceNotDetectedAlert =
    !isFaceDetected && !isVideoRecording && !isRecordingRestarted;

  return (
    <Stack sx={{ position: 'absolute', top: '6rem', zIndex: 2 }}>
      {showStartRecordingAlert && (
        <Alert severity="success">Puedes comenzar a grabar</Alert>
      )}
      {showFaceNotDetectedAlert && (
        <Alert severity="error">Tu rostro debe estar visible</Alert>
      )}
      {isRecordingRestarted && (
        <Alert
          severity="error"
          actionLabel="Reintentar"
          onAction={() => setIsRecordingRestarted(false)}
        >
          Tu rostro debe estar visible. Vuelve a intentarlo
        </Alert>
      )}
    </Stack>
  );
};

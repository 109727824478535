import gql from 'graphql-tag';

export const GET_ALL_VIDEO_AGREEMENT_TEMPLATES = gql`
  query GetAllVideoAgreementTemplates {
    getAllVideoAgreementTemplates {
      videoAgreementTemplates {
        _id
        name
        text
        color
      }
    }
  }
`;

export const CREATE_VIDEO_AGREEMENT_TEMPLATE = gql`
  mutation CreateVideoAgreementTemplate(
    $input: CreateVideoAgreementTemplateInput!
  ) {
    createVideoAgreementTemplate(input: $input) {
      ... on VideoAgreementTemplate {
        _id
        name
        text
        color
      }
      ... on VideoAgreementTemplateAlreadyExists {
        name
      }
    }
  }
`;

export const UPDATE_VIDEO_AGREEMENT_TEMPLATE = gql`
  mutation UpdateVideoAgreementTemplate(
    $input: UpdateVideoAgreementTemplateInput!
  ) {
    updateVideoAgreementTemplate(input: $input) {
      ... on VideoAgreementTemplate {
        _id
        name
        text
        color
      }
      ... on VideoAgreementTemplateAlreadyExists {
        name
      }
    }
  }
`;

export const DELETE_VIDEO_AGREEMENT_TEMPLATE = gql`
  mutation DeleteVideoAgreementTemplate(
    $input: DeleteVideoAgreementTemplateInput!
  ) {
    deleteVideoAgreementTemplate(input: $input) {
      deletedCount
    }
  }
`;

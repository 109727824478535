import { Icon } from '../../../../../components/Icons/Icons';
import { Avatar, AvatarSelectedHover } from '../../../../../newComponents';
import { VideoAgreementTemplateAvatarProps } from './VideoAgreementTemplateAvatar.types';

export const VideoAgreementTemplateAvatar = (
  props: VideoAgreementTemplateAvatarProps,
) => {
  const { color, selected = false, ...avatarProps } = props;

  return (
    <Avatar size="medium" style={{ backgroundColor: color }} {...avatarProps}>
      <Icon width="20px" icon="vidicon_line" style={{ color: 'white' }} />
      {selected && <AvatarSelectedHover />}
    </Avatar>
  );
};

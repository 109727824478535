import { styled, Stack, DialogContent } from '@mui/material';
import { IconButton } from '../../../../newComponents/IconButton';

export const OptionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.newPalette.grey.grey700,
  ':hover': { backgroundColor: theme.newPalette.grey.grey700 },
}));

export const CaptureButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.newPalette.error.main,
  ':hover': { backgroundColor: theme.newPalette.error.main },
  ':disabled': { backgroundColor: theme.newPalette.error.darker },
}));

type CustomDialogContentProps = {
  isMobile: boolean;
  isLoading: boolean;
  isFaceDetected: boolean;
  isRecordingRestarted: boolean;
};

export const CustomDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) =>
    prop !== 'isMobile' &&
    prop !== 'isFaceDetected' &&
    prop !== 'isLoading' &&
    prop !== 'isRecordingRestarted',
})<CustomDialogContentProps>(({
  isMobile,
  isFaceDetected,
  isLoading,
  isRecordingRestarted,
}) => {
  let border = 'none';
  if (!isLoading) {
    if (isFaceDetected && !isRecordingRestarted) {
      border = '10px solid #0FCEA97A';
    } else {
      border = '10px solid #F95C5E7A';
    }
  }
  return {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    border,
    borderRadius: isMobile ? '0px' : '16px',
  };
});

type CameraVideoWrapperProps = {
  isLoading: boolean;
};

export const CameraVideoWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})<CameraVideoWrapperProps>(({ isLoading }) => ({
  display: isLoading ? 'none' : 'flex',
  backgroundColor: '#000000',
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '100%',
  maxHeight: '100%',
}));

type CameraHeaderWrapperProps = {
  showHeaderControls: boolean;
  isMobile: boolean;
};

export const CameraHeaderWrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== 'showHeaderControls' && prop !== 'isMobile',
})<CameraHeaderWrapperProps>(({ showHeaderControls, isMobile }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: showHeaderControls ? 'space-between' : 'center',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  alignItems: 'center',
  right: '10px',
  top: '10px',
  left: '10px',
  flexDirection: 'row',
  width: 'calc(100% - 20px)',
  height: '4rem',
  borderTopLeftRadius: isMobile ? '0px' : '5px',
  borderTopRightRadius: isMobile ? '0px' : '5px',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 2,
}));

type CameraFooterWrapperProps = {
  isMobile: boolean;
};

export const CameraFooterWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<CameraFooterWrapperProps>(({ isMobile }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  bottom: '10px',
  right: '10px',
  left: '10px',
  boxSizing: 'border-box',
  flexDirection: 'row',
  width: 'calc(100% - 20px)',
  height: '4rem',
  borderBottomLeftRadius: isMobile ? '0px' : '5px',
  borderBottomRightRadius: isMobile ? '0px' : '5px',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 2,
}));

type VideoProps = {
  flipImage: boolean;
};

export const Video = styled('video', {
  shouldForwardProp: (prop) => prop !== 'flipImage',
})<VideoProps>(({ flipImage }) => ({
  transform: flipImage ? 'scale(-1,1)' : 'scale(1,1)',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

type VideoAgreementTextWrapperProps = {
  isMobile: boolean;
};

export const VideoAgreementTextWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<VideoAgreementTextWrapperProps>(({ isMobile }) => ({
  position: 'absolute',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '16px',
  padding: '10px',
  bottom: '6rem',
  width: '90%',
  height: isMobile ? '110px' : '170px',
  overflowY: 'auto',
  zIndex: 2,
}));

export const ScrollText = styled('span')(() => ({
  bottom: 'calc(8rem + 300px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 'normal',
  height: '4rem',
  color: 'white',
}));

import { globalSnackbarVar } from '../../../cache.reactiveVars';

/**
 * @param {string} text
 * @param {string} message
 */
export const copyToClipboard = (text, message) => {
  navigator.clipboard.writeText(text);
  globalSnackbarVar({
    show: true,
    message: message || 'Copied to clipboard',
    duration: 1000,
  });
};

export const getCategorizedColors = (colors) => {
  const transparents = {};
  const gradients = {};
  const mainColors = {};

  for (const key in colors) {
    if (key.includes('transparent')) {
      transparents[key] = colors[key];
    } else if (key === 'gradient') {
      gradients[key] = colors[key];
    } else {
      mainColors[key] = colors[key];
    }
  }

  return {
    transparents,
    gradients,
    mainColors,
  };
};

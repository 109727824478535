import { globalSnackbarVar } from '../../../../../cache.reactiveVars';
// eslint-disable-next-line no-unused-vars
import { UseFormReturn } from 'react-hook-form';
import { resetSendOnboardingNotifDialogVar } from '../../Employees.vars';

/**
 * @param {object} params
 * @param {object} params.response
 * @param {Function} params.refetchFunc
 * @param {UseFormReturn<{email: string;}, any, undefined>} [params.form]
 */
export function handleSendOnboardingNotifResponse({
  response,
  form,
  refetchFunc,
}) {
  const __typename = response?.sendOnboardingNotification?.__typename;
  const message = response?.sendOnboardingNotification?.message;
  const esMessage = response?.sendOnboardingNotification?.esMessage || message;

  if (__typename === 'SendOnboardingNotificationSuccess') {
    globalSnackbarVar({
      show: true,
      severity: 'success',
      message: esMessage,
    });
    refetchFunc();
    return resetSendOnboardingNotifDialogVar();
  }

  if (__typename === 'UserNotFound') {
    globalSnackbarVar({
      show: true,
      severity: 'error',
      message: esMessage,
    });
    return resetSendOnboardingNotifDialogVar();
  }

  if (__typename === 'NotificationTimeout') {
    globalSnackbarVar({
      show: true,
      severity: 'error',
      message: esMessage,
    });
    return resetSendOnboardingNotifDialogVar();
  }

  if (__typename === 'UserAlreadyActive') {
    globalSnackbarVar({
      show: true,
      severity: 'warning',
      message: esMessage,
    });
    refetchFunc();
    return resetSendOnboardingNotifDialogVar();
  }

  if (__typename === 'EmailAlreadyInUse') {
    // Keep dialog open and set error
    globalSnackbarVar({
      show: true,
      severity: 'error',
      message: esMessage,
    });
    return form?.setError('email', {
      type: 'manual',
      message: esMessage,
    });
  }

  globalSnackbarVar({
    show: true,
    severity: 'error',
    message:
      'Ocurrio un problema con tu petición, intenta de nuevo o contacta a soporte.',
  });
  return resetSendOnboardingNotifDialogVar();
}

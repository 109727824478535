import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Button } from '../../../../../newComponents/Button';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { useScreenSize } from '../../../../../Hooks';
import { VideoAgreementPromptForm } from './VideoAgreementPromptForm';
import {
  videoAgreementPromptDialogVar,
  resetVideoAgreementPromptDialog,
} from './VideoAgreementPromptDialog.vars';

export function VideoAgreementPromptDialog() {
  const { isMobile } = useScreenSize();
  const { open, videoAgreementText, onContinue } = useReactiveVar(
    videoAgreementPromptDialogVar,
  );

  const form = useForm({
    mode: 'onChange',
    defaultValues: { text: '' },
  });

  const handleClose = () => {
    resetVideoAgreementPromptDialog();
    form.reset();
  };

  useEffect(() => {
    form.reset({ text: videoAgreementText });
  }, [form, videoAgreementText]);

  const formIsValid = form.formState.isValid;

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => {}}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        component="div"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          Configurar video acuerdo
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <VideoAgreementPromptForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          size={isMobile ? 'medium' : 'large'}
          disabled={!formIsValid}
          onClick={() => {
            onContinue(form.getValues().text);
            handleClose();
          }}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// @ts-check

/**
 * @typedef {import('@mui/material/styles').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiFab']} StyleOverrides
 * @typedef {import('@mui/material/styles').ComponentsVariants['MuiFab']} Variants
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    fontWeight: '700',
    letterSpacing: '0px',
    textTransform: 'none',
    gap: 5,
    color: theme.newPalette.common.white,
    '&.MuiFab-extended': {
      borderRadius: '50px',
      '&.MuiFab-sizeSmall': { height: '34px' },
      '&.MuiFab-sizeMedium': { height: '40px' },
      '&.MuiFab-sizeLarge': { height: '50px' },
    },
    '&.MuiFab-sizeSmall': { height: '40px' },
    '&.MuiFab-sizeMedium': { height: '48px' },
    '&.MuiFab-sizeLarge': { height: '56px' },
  }),
};

/** @type {Variants} */
const fabVariants = [
  {
    // @ts-ignore
    props: { color: 'default' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.grey.transparent24,
      boxShadow: theme.newPalette.shadow.customBuilder(
        'z8',
        theme.newPalette.grey.transparent24,
      ),
      color: theme.newPalette.text.primary,
      '&:hover': {
        backgroundColor: theme.newPalette.grey.transparent32,
      },
    }),
  },
  {
    // @ts-ignore
    props: { color: 'primary' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.primary.main,
      boxShadow: theme.newPalette.shadow.customBuilder(
        'z8',
        theme.newPalette.primary.transparent24,
      ),
      '&:hover': {
        backgroundColor: theme.newPalette.primary.dark,
      },
    }),
  },
  {
    // @ts-ignore
    props: { color: 'secondary' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.secondary.main,
      boxShadow: theme.newPalette.shadow.customBuilder(
        'z8',
        theme.newPalette.secondary.transparent24,
      ),
      '&:hover': {
        backgroundColor: theme.newPalette.secondary.dark,
      },
    }),
  },
  {
    // @ts-ignore
    props: { color: 'info' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.info.main,
      boxShadow: theme.newPalette.shadow.customBuilder(
        'z8',
        theme.newPalette.info.transparent24,
      ),
      '&:hover': {
        backgroundColor: theme.newPalette.info.dark,
      },
    }),
  },
  {
    // @ts-ignore
    props: { color: 'success' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.success.main,
      boxShadow: theme.newPalette.shadow.customBuilder(
        'z8',
        theme.newPalette.success.transparent24,
      ),
      '&:hover': {
        backgroundColor: theme.newPalette.success.dark,
      },
    }),
  },
  {
    // @ts-ignore
    props: { color: 'warning' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.warning.main,
      boxShadow: theme.newPalette.shadow.customBuilder(
        'z8',
        theme.newPalette.warning.transparent24,
      ),
      '&:hover': {
        backgroundColor: theme.newPalette.warning.dark,
      },
    }),
  },
  {
    // @ts-ignore
    props: { color: 'error' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.error.main,
      boxShadow: theme.newPalette.shadow.customBuilder(
        'z8',
        theme.newPalette.error.transparent24,
      ),
      '&:hover': {
        backgroundColor: theme.newPalette.error.dark,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'outlined', color: 'default' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      border: `1px solid ${theme.newPalette.grey.transparent24}`,
      boxShadow: theme.newPalette.shadow.z24,
      color: theme.newPalette.text.primary,
      '&:hover': {
        backgroundColor: theme.newPalette.grey.transparent32,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'outlined', color: 'primary' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      border: `1px solid ${theme.newPalette.primary.main}`,
      color: theme.newPalette.primary.main,
      backgroundColor: theme.newPalette.common.whiteTransparent.transparent8,
      boxShadow: theme.newPalette.shadow.z24,
      '&:hover': {
        backgroundColor: theme.newPalette.primary.transparent12,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'outlined', color: 'secondary' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      border: `1px solid ${theme.newPalette.secondary.main}`,
      color: theme.newPalette.secondary.main,
      backgroundColor: theme.newPalette.common.whiteTransparent.transparent8,
      boxShadow: theme.newPalette.shadow.z24,
      '&:hover': {
        backgroundColor: theme.newPalette.secondary.transparent12,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'outlined', color: 'info' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      border: `1px solid ${theme.newPalette.info.main}`,
      color: theme.newPalette.info.main,
      backgroundColor: theme.newPalette.common.whiteTransparent.transparent8,
      boxShadow: theme.newPalette.shadow.z24,
      '&:hover': {
        backgroundColor: theme.newPalette.info.transparent12,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'outlined', color: 'success' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      border: `1px solid ${theme.newPalette.success.main}`,
      color: theme.newPalette.success.main,
      backgroundColor: theme.newPalette.common.whiteTransparent.transparent8,
      boxShadow: theme.newPalette.shadow.z24,
      '&:hover': {
        backgroundColor: theme.newPalette.success.transparent12,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'outlined', color: 'warning' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      border: `1px solid ${theme.newPalette.warning.main}`,
      color: theme.newPalette.warning.main,
      backgroundColor: theme.newPalette.common.whiteTransparent.transparent8,
      boxShadow: theme.newPalette.shadow.z24,
      '&:hover': {
        backgroundColor: theme.newPalette.warning.transparent12,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'outlined', color: 'error' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      border: `1px solid ${theme.newPalette.error.main}`,
      color: theme.newPalette.error.main,
      backgroundColor: theme.newPalette.common.whiteTransparent.transparent8,
      boxShadow: theme.newPalette.shadow.z24,
      '&:hover': {
        backgroundColor: theme.newPalette.error.transparent12,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'soft', color: 'default' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.grey.transparent16,
      boxShadow: 'none',
      color: theme.newPalette.text.primary,
      '&:hover': {
        backgroundColor: theme.newPalette.grey.transparent24,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'soft', color: 'primary' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.primary.transparent16,
      color: theme.newPalette.primary.dark,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.newPalette.primary.transparent24,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'soft', color: 'secondary' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.secondary.transparent16,
      color: theme.newPalette.secondary.dark,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.newPalette.secondary.transparent24,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'soft', color: 'info' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.info.transparent16,
      color: theme.newPalette.info.dark,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.newPalette.info.transparent24,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'soft', color: 'success' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.success.transparent16,
      color: theme.newPalette.success.dark,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.newPalette.success.transparent24,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'soft', color: 'warning' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.warning.transparent16,
      color: theme.newPalette.warning.dark,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.newPalette.warning.transparent24,
      },
    }),
  },
  {
    // @ts-ignore
    props: { look: 'soft', color: 'error' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: theme.newPalette.error.transparent16,
      color: theme.newPalette.error.dark,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.newPalette.error.transparent24,
      },
    }),
  },
  {
    // @ts-ignore
    props: { disabled: true },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: `${theme.newPalette.grey.transparent24} !important`,
      color: `${theme.newPalette.actionStates.disabled} !important`,
      boxShadow: 'none !important',
      border: 'none !important',
      '& > svg': {
        color: `${theme.newPalette.text.primary} !important`,
      },
    }),
  },
  {
    // @ts-ignore
    props: { disabled: true, look: 'outlined' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      border: `1px solid ${theme.newPalette.grey.transparent24} !important`,
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
      '& > svg': {
        color: `${theme.newPalette.text.primary} !important`,
      },
    }),
  },
  {
    // @ts-ignore
    props: { disabled: true, look: 'soft' },
    // @ts-ignore
    style: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
      backgroundColor: `${theme.newPalette.grey.transparent24} !important`,
      color: `${theme.newPalette.actionStates.disabled} !important`,
      boxShadow: 'none !important',
      border: 'none !important',
      '& > svg': {
        color: `${theme.newPalette.actionStates.disabled} !important`,
      },
    }),
  },
];

/** @type {{ styleOverrides: StyleOverrides, variants: Variants }} */
export const fabButtonStyles = {
  styleOverrides,
  variants: fabVariants,
};

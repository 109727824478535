import { makeVar } from '@apollo/client';

type VideoAgreementRecorderDialogVar = {
  open: boolean;
  textToRead: string;
  step: number;
  videoRecorded: Blob | null;
  videoDuration: number;
};

const videoAgreementRecorderDialogDefault: VideoAgreementRecorderDialogVar = {
  open: false,
  textToRead: '',
  step: 0,
  videoRecorded: null,
  videoDuration: 60,
};

export const videoAgreementRecorderDialogVar = makeVar(
  videoAgreementRecorderDialogDefault,
);

export const resetVideoAgreementRecorderDialogVar = () => {
  videoAgreementRecorderDialogVar(videoAgreementRecorderDialogDefault);
};

export const startVideoAgreementRecorder = (
  input: Pick<VideoAgreementRecorderDialogVar, 'textToRead'>,
) => {
  videoAgreementRecorderDialogVar({
    ...videoAgreementRecorderDialogVar(),
    open: true,
    step: 0,
    textToRead: input.textToRead,
  });
};

export const setVideoAgreementRecorderDialogVar = (
  input: Partial<VideoAgreementRecorderDialogVar>,
) => {
  videoAgreementRecorderDialogVar({
    ...videoAgreementRecorderDialogVar(),
    ...input,
  });
};

import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../newComponents/Dialog';
import { DialogProps } from '../../../newComponents/Dialog/Dialog.types';
import { videoAgreementRecorderDialogVar } from './VideoAgreementRecorderDialog.vars';
import { VideoAgreementRecorderInfo } from './VideoAgreementRecorderInfo';
import { VideoAgreementRecorderInstructions } from './VideoAgreementRecorderInstructions';
import { VideoAgreementRecorderCamera } from './VideoAgreementRecorderCamera';
import { VideoAgreementRecorderResume } from './VideoAgreementRecorderResume';

export function VideoAgreementRecorderDialog() {
  const { open, step } = useReactiveVar(videoAgreementRecorderDialogVar);

  const dynamicProps: Partial<DialogProps> =
    step === 2
      ? { PaperProps: { sx: { backgroundColor: 'black' } } }
      : { maxWidth: 'lg' };

  return (
    <Dialog fullWidth open={open} {...dynamicProps}>
      {step === 0 && <VideoAgreementRecorderInfo />}
      {step === 1 && <VideoAgreementRecorderInstructions />}
      {step === 2 && <VideoAgreementRecorderCamera />}
      {step === 3 && <VideoAgreementRecorderResume />}
    </Dialog>
  );
}

// @ts-check
import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useController } from 'react-hook-form';
import { ColorSelector } from '../../../newComponents/ColorSelector';
import { Typography } from '../../../newComponents/Typography';

/** @type {import('./ControlledColorInput.types').Rules} */
const defaultRules = {
  required: 'Campo requerido',
};

/** @param {import('./ControlledColorInput.types').ControlledColorInputProps} props */
export const ControlledColorInput = ({
  control,
  rules = {},
  disabled = false,
  onColorChange = () => {},
}) => {
  const name = 'color';
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules: { ...defaultRules, ...rules },
  });

  useEffect(() => {
    onColorChange(value);
  }, [value, onColorChange]);

  return (
    <Stack>
      <Typography variant="subtitle1">Color</Typography>
      <ColorSelector
        color={value}
        onChange={(color) => {
          if (disabled) return;
          onChange(color);
          onColorChange(color);
        }}
        ommitGradients
        size="small"
      />
    </Stack>
  );
};

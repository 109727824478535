import { makeVar } from '@apollo/client';

type VideoAgreementPromptDialog = {
  open: boolean;
  videoAgreementText: string;
  onContinue: (videoAgreementText: string) => void;
};

const defaultVideoAgreementPromptDialog: VideoAgreementPromptDialog = {
  open: false,
  videoAgreementText: '',
  onContinue: () => {},
};

export const videoAgreementPromptDialogVar =
  makeVar<VideoAgreementPromptDialog>(defaultVideoAgreementPromptDialog);

export const setVideoAgreementPromptDialog = (
  params: Partial<VideoAgreementPromptDialog>,
) => {
  videoAgreementPromptDialogVar({
    ...videoAgreementPromptDialogVar(),
    ...params,
  });
};

export const resetVideoAgreementPromptDialog = () => {
  videoAgreementPromptDialogVar(defaultVideoAgreementPromptDialog);
};

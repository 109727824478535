//@ts-check
/** @type {import('../types/colors.types').BaseShadows} */
const baseShadows = {
  z1: '0px 1px 2px ',
  z8: '0px 8px 16px ',
  z12: '0px 12px 24px -4px ',
  z16: '0px 16px 32px -4px ',
  z20: '0px 20px 40px -4px ',
  z24: '0px 24px 48px ',
  color: 'rgba(145, 158, 171, 0.16)',
};

/** @type {import('../types/colors.types').ColorMode} */
export const onLightcolors = {
  mode: 'light',
  primary: {
    lighter: '#D3DDFD',
    light: '#7B95F8',
    main: '#2648E8',
    dark: '#1327A7',
    darker: '#07116F',
    transparent8: 'rgba(38, 72, 232, 0.08)',
    transparent12: 'rgba(38, 72, 232, 0.12)',
    transparent16: 'rgba(38, 72, 232, 0.16)',
    transparent24: 'rgba(38, 72, 232, 0.24)',
    transparent32: 'rgba(38, 72, 232, 0.32)',
    transparent48: 'rgba(38, 72, 232, 0.48)',
    gradient: 'linear-gradient(135deg, #5A46FB 0%, #1E95FF 100%)',
  },
  secondary: {
    lighter: '#D2F3FF',
    light: '#78CDFF',
    main: '#1E95FF',
    dark: '#0F56B7',
    darker: '#052A7A',
    transparent8: 'rgba(30, 149, 255, 0.08)',
    transparent12: 'rgba(30, 149, 255, 0.12)',
    transparent16: 'rgba(30, 149, 255, 0.16)',
    transparent24: 'rgba(30, 149, 255, 0.24)',
    transparent32: 'rgba(30, 149, 255, 0.32)',
    transparent48: 'rgba(30, 149, 255, 0.48)',
    gradient:
      'linear-gradient(135deg, #4FD5D9 28.13%, #3A2BE3 100%, #3A2BE3 100%)',
  },
  info: {
    lighter: '#DFDAFE',
    light: '#9E8FFD',
    main: '#5A46FB',
    dark: '#2F23B4',
    darker: '#140D78',
    transparent8: 'rgba(90, 70, 251, 0.08)',
    transparent12: 'rgba(90, 70, 251, 0.12)',
    transparent16: 'rgba(90, 70, 251, 0.16)',
    transparent24: 'rgba(90, 70, 251, 0.24)',
    transparent32: 'rgba(90, 70, 251, 0.32)',
    transparent48: 'rgba(90, 70, 251, 0.48)',
    gradient: 'linear-gradient(135deg, #2F23B4 0%, #9E8FFD 100%)',
  },
  success: {
    lighter: '#CFFEDE',
    light: '#71FAB7',
    main: '#0FCEA9',
    dark: '#068B8B',
    darker: '#046873',
    transparent8: 'rgba(15, 206, 169, 0.08)',
    transparent12: 'rgba(15, 206, 169, 0.12)',
    transparent16: 'rgba(15, 206, 169, 0.16)',
    transparent24: 'rgba(15, 206, 169, 0.24)',
    transparent32: 'rgba(15, 206, 169, 0.32)',
    transparent48: 'rgba(15, 206, 169, 0.48)',
    gradient: 'linear-gradient(135deg, #15F06E 0%, #1E95FF 100%)',
  },
  warning: {
    lighter: '#FFF6D6',
    light: '#FFDB83',
    main: '#FFAB00',
    dark: '#B77119',
    darker: '#7A4009',
    transparent8: 'rgba(255, 180, 50, 0.08)',
    transparent12: 'rgba(255, 180, 50, 0.12)',
    transparent16: 'rgba(255, 180, 50, 0.16)',
    transparent24: 'rgba(255, 180, 50, 0.24)',
    transparent32: 'rgba(255, 180, 50, 0.32)',
    transparent48: 'rgba(255, 180, 50, 0.48)',
    gradient: 'linear-gradient(135deg, #FFAB00 0%, #FFF6D6 100%)',
  },
  error: {
    lighter: '#FEE9DE',
    light: '#FDAC9D',
    main: '#F95C5E',
    dark: '#B32E47',
    darker: '#771136',
    transparent8: 'rgba(249, 92, 94, 0.08)',
    transparent12: 'rgba(249, 92, 94, 0.12)',
    transparent16: 'rgba(249, 92, 94, 0.16)',
    transparent24: 'rgba(249, 92, 94, 0.24)',
    transparent32: 'rgba(249, 92, 94, 0.32)',
    transparent48: 'rgba(249, 92, 94, 0.48)',
    gradient: 'linear-gradient(135deg, #F95C5E 0%, #FFAB00 100%)',
  },
  grey: {
    grey100: '#F9FAFB',
    grey200: '#F4F6F8',
    grey300: '#DFE3E8',
    grey400: '#C4CDD5',
    grey500: '#919EAB',
    grey600: '#637381',
    grey700: '#454F5B',
    grey800: '#212B36',
    grey900: '#161C24',
    transparent8: 'rgba(145, 158, 171, 0.08)',
    transparent12: 'rgba(145, 158, 171, 0.12)',
    transparent16: 'rgba(145, 158, 171, 0.16)',
    transparent24: 'rgba(145, 158, 171, 0.24)',
    transparent32: 'rgba(145, 158, 171, 0.32)',
    transparent48: 'rgba(145, 158, 171, 0.48)',
  },
  common: {
    black: '#000000',
    blackTransparent: {
      transparent8: 'rgba(0, 0, 0, 0.08)',
      transparent12: 'rgba(0, 0, 0, 0.12)',
      transparent16: 'rgba(0, 0, 0, 0.16)',
      transparent24: 'rgba(0, 0, 0, 0.24)',
      transparent32: 'rgba(0, 0, 0, 0.32)',
      transparent48: 'rgba(0, 0, 0, 0.48)',
    },
    white: '#FFFFFF',
    whiteTransparent: {
      transparent8: 'rgba(255, 255, 255, 0.08)',
      transparent12: 'rgba(255, 255, 255, 0.12)',
      transparent16: 'rgba(255, 255, 255, 0.16)',
      transparent24: 'rgba(255, 255, 255, 0.24)',
      transparent32: 'rgba(255, 255, 255, 0.32)',
      transparent48: 'rgba(255, 255, 255, 0.48)',
    },
  },
  text: {
    primary: '#212B36',
    secondary: '#637381',
    disabled: '#919EAB',
  },
  divider: 'rgba(145, 158, 171, 0.24)',
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
    neutral: '#F4F6F8',
    pdfViewer: '#4d525d',
    overlay: {
      solid: {
        dark: 'rgba(22, 28, 36, 0.80)',
      },
      gradient: {
        dark: {
          toBottom:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
        },
      },
    },
  },
  actionStates: {
    active: '#637381',
    hover: 'rgba(145, 158, 171, 0.08)',
    selected: 'rgba(145, 158, 171, 0.16)',
    disabled: 'rgba(145, 158, 171, 0.8)',
    disabledBackground: 'rgba(145, 158, 171, 0.24)',
    focus: 'rgba(145, 158, 171, 0.24)',
  },
  shadow: {
    z1: `${baseShadows.z1} ${baseShadows.color}`,
    z8: `${baseShadows.z8} ${baseShadows.color}`,
    z12: `${baseShadows.z12} ${baseShadows.color}`,
    z16: `${baseShadows.z16} ${baseShadows.color}`,
    z20: `${baseShadows.z20} ${baseShadows.color}`,
    z24: `${baseShadows.z24} ${baseShadows.color}`,
    card: '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px rgba(145, 158, 171, 0.2)',
    button: '0px 8px 16px rgba(38, 72, 232, 0.24)',
    dialog: '-40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
    dropdown:
      '-20px 20px 40px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px rgba(145, 158, 171, 0.24)',
    customBuilder: (z, color) =>
      `${baseShadows[z] ?? '0px 1px 2px'} ${color ?? baseShadows.color}`,
  },
};

export const onDarkcolors = {
  mode: 'dark',
  primary: {
    lighter: '#D3DDFD',
    light: '#7B95F8',
    main: '#2648E8',
    dark: '#1327A7',
    darker: '#07116F',
    transparent8: 'rgba(38, 72, 232, 0.08)',
    transparent12: 'rgba(38, 72, 232, 0.12)',
    transparent16: 'rgba(38, 72, 232, 0.16)',
    transparent24: 'rgba(38, 72, 232, 0.24)',
    transparent32: 'rgba(38, 72, 232, 0.32)',
    transparent48: 'rgba(38, 72, 232, 0.48)',
    gradient: 'linear-gradient(135deg, #5A46FB 0%, #1E95FF 100%)',
  },
  secondary: {
    lighter: '#D2F3FF',
    light: '#78CDFF',
    main: '#1E95FF',
    dark: '#0F56B7',
    darker: '#052A7A',
    transparent8: 'rgba(30, 149, 255, 0.08)',
    transparent12: 'rgba(30, 149, 255, 0.12)',
    transparent16: 'rgba(30, 149, 255, 0.16)',
    transparent24: 'rgba(30, 149, 255, 0.24)',
    transparent32: 'rgba(30, 149, 255, 0.32)',
    transparent48: 'rgba(30, 149, 255, 0.48)',
    gradient:
      'linear-gradient(135deg, #4FD5D9 28.13%, #3A2BE3 100%, #3A2BE3 100%)',
  },
  info: {
    lighter: '#DFDAFE',
    light: '#9E8FFD',
    main: '#5A46FB',
    dark: '#2F23B4',
    darker: '#140D78',
    transparent8: 'rgba(90, 70, 251, 0.08)',
    transparent12: 'rgba(90, 70, 251, 0.12)',
    transparent16: 'rgba(90, 70, 251, 0.16)',
    transparent24: 'rgba(90, 70, 251, 0.24)',
    transparent32: 'rgba(90, 70, 251, 0.32)',
    transparent48: 'rgba(90, 70, 251, 0.48)',
    gradient: 'linear-gradient(135deg, #2F23B4 0%, #9E8FFD 100%)',
  },
  success: {
    lighter: '#CFFEDE',
    light: '#71FAB7',
    main: '#0FCEA9',
    dark: '#068B8B',
    darker: '#046873',
    transparent8: 'rgba(15, 206, 169, 0.08)',
    transparent12: 'rgba(15, 206, 169, 0.12)',
    transparent16: 'rgba(15, 206, 169, 0.16)',
    transparent24: 'rgba(15, 206, 169, 0.24)',
    transparent32: 'rgba(15, 206, 169, 0.32)',
    transparent48: 'rgba(15, 206, 169, 0.48)',
    gradient: 'linear-gradient(135deg, #15F06E 0%, #1E95FF 100%)',
  },
  warning: {
    lighter: '#FFF6D6',
    light: '#FFDB83',
    main: '#FFAB00',
    dark: '#B77119',
    darker: '#7A4009',
    transparent8: 'rgba(255, 180, 50, 0.08)',
    transparent12: 'rgba(255, 180, 50, 0.12)',
    transparent16: 'rgba(255, 180, 50, 0.16)',
    transparent24: 'rgba(255, 180, 50, 0.24)',
    transparent32: 'rgba(255, 180, 50, 0.32)',
    transparent48: 'rgba(255, 180, 50, 0.48)',
    gradient: 'linear-gradient(135deg, #FFAB00 0%, #FFF6D6 100%)',
  },
  error: {
    lighter: '#FEE9DE',
    light: '#FDAC9D',
    main: '#F95C5E',
    dark: '#B32E47',
    darker: '#771136',
    transparent8: 'rgba(249, 92, 94, 0.08)',
    transparent12: 'rgba(249, 92, 94, 0.12)',
    transparent16: 'rgba(249, 92, 94, 0.16)',
    transparent24: 'rgba(249, 92, 94, 0.24)',
    transparent32: 'rgba(249, 92, 94, 0.32)',
    transparent48: 'rgba(249, 92, 94, 0.48)',
    gradient: 'linear-gradient(135deg, #F95C5E 0%, #FFAB00 100%)',
  },
  grey: {
    grey100: '#F9FAFB',
    grey200: '#F4F6F8',
    grey300: '#DFE3E8',
    grey400: '#C4CDD5',
    grey500: '#919EAB',
    grey600: '#637381',
    grey700: '#454F5B',
    grey800: '#212B36',
    grey900: '#161C24',
    transparent8: 'rgba(145, 158, 171, 0.08)',
    transparent12: 'rgba(145, 158, 171, 0.12)',
    transparent16: 'rgba(145, 158, 171, 0.16)',
    transparent24: 'rgba(145, 158, 171, 0.24)',
    transparent32: 'rgba(145, 158, 171, 0.32)',
    transparent48: 'rgba(145, 158, 171, 0.48)',
  },
  common: {
    black: '#000000',
    blackTransparent: {
      transparent8: 'rgba(0, 0, 0, 0.08)',
      transparent12: 'rgba(0, 0, 0, 0.12)',
      transparent16: 'rgba(0, 0, 0, 0.16)',
      transparent24: 'rgba(0, 0, 0, 0.24)',
      transparent32: 'rgba(0, 0, 0, 0.32)',
      transparent48: 'rgba(0, 0, 0, 0.48)',
    },
    white: '#FFFFFF',
    whiteTransparent: {
      transparent8: 'rgba(255, 255, 255, 0.08)',
      transparent12: 'rgba(255, 255, 255, 0.12)',
      transparent16: 'rgba(255, 255, 255, 0.16)',
      transparent24: 'rgba(255, 255, 255, 0.24)',
      transparent32: 'rgba(255, 255, 255, 0.32)',
      transparent48: 'rgba(255, 255, 255, 0.48)',
    },
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#919EAB',
    disabled: '#637381',
  },
  divider: 'rgba(145, 158, 171, 0.24)',
  background: {
    default: '#161C24',
    paper: '#212B36',
    neutral: 'rgba(145, 158, 171, 0.16)',
    pdfViewer: '#4d525d',
  },
  actionStates: {
    active: '#919EAB',
    hover: 'rgba(145, 158, 171, 0.08)',
    selected: 'rgba(145, 158, 171, 0.16)',
    disabled: 'rgba(145, 158, 171, 0.8)',
    disabledBackground: 'rgba(145, 158, 171, 0.24)',
    focus: 'rgba(145, 158, 171, 0.24)',
  },

  shadow: {
    z1: '0px 1px 2px rgba(0, 0, 0, 0.16)',
    z8: '0px 8px 16px rgba(0, 0, 0, 0.16)',
    z12: '0px 12px 24px -4px rgba(0, 0, 0, 0.16)',
    z16: '0px 16px 32px -4px rgba(0, 0, 0, 0.16)',
    z20: '0px 20px 40px -4px rgba(0, 0, 0, 0.16)',
    z24: '0px 24px 48px rgba(0, 0, 0, 0.16)',
    button: '0px 8px 16px rgba(38, 72, 232, 0.24)',
    card: '0px 12px 24px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.2)',
    dialog: '-40px 40px 80px -8px rgba(0, 0, 0, 0.24)',
    dropdown:
      '-20px 20px 40px -4px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.24)',
    customBuilder: (z, color) =>
      `${baseShadows[z] ?? '0px 1px 2px'} ${color ?? baseShadows.color}`,
  },
};

import { createContext, forwardRef, useContext } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Box, Stack, Typography } from '@mui/material';
import { VideoAgreementTemplateAvatar } from '../VideoAgreementTemplateAvatar';

const ITEM_SIZE = 56;

const OuterElementContext = createContext({});
const OuterElementType = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const VideoAgreementTemplatesFinderItemWrapper = (
  props: ListChildComponentProps,
) => {
  const { index, style, data } = props;
  const [parentProps, template] = data[index];
  const { key, ...restProps } = parentProps;

  const isSelected = parentProps['aria-selected'];

  return (
    <Box key={key} {...restProps} style={style}>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        width="100%"
        flexWrap="nowrap"
      >
        <VideoAgreementTemplateAvatar
          color={template.color}
          selected={isSelected}
          size="small"
        />
        <Typography variant="body1">{template.name}</Typography>
      </Stack>
    </Box>
  );
};

export const VideoAgreementTemplatesFinderList = forwardRef<
  HTMLDivElement,
  { children: [any, any][] }
>((props, ref) => {
  const { children, ...other } = props;
  const templates = children.map((child) => child[1]);

  return (
    <Box ref={ref} style={{ padding: 0, maxHeight: '100%' }}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          height={
            templates?.length
              ? Math.min(templates?.length * ITEM_SIZE, 300)
              : ITEM_SIZE
          }
          width="100%"
          itemSize={ITEM_SIZE}
          itemCount={templates.length}
          itemData={children}
          style={{ margin: '5px 0px' }}
          outerElementType={OuterElementType}
        >
          {VideoAgreementTemplatesFinderItemWrapper}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Box>
  );
});

import React from 'react';
import { Autocomplete } from '@mui/material';
import { useGetVideoAgreementTemplates } from '../VideoAgreementTemplatesHooks';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  Chip,
  CircularProgress,
  TextInput,
} from '../../../../../newComponents';
import { VideoAgreementTemplatesFinderList } from './VideoAgreementTemplatesFinderList';
import { VideoAgreementTemplatesFinderProps } from './VideoAgreementTemplatesFinder.types';

export function VideoAgreementTemplatesFinder(
  props: VideoAgreementTemplatesFinderProps,
) {
  const { TextFieldProps, onSelected } = props;
  const { videoAgreementTemplates, loading } = useGetVideoAgreementTemplates({
    showBackdrop: false,
  });

  return (
    <Autocomplete
      id="video-agreement-templates-finder"
      multiple={false}
      options={videoAgreementTemplates}
      getOptionLabel={(option) => option.name}
      renderTags={(value, getTagProps) => (
        <div>
          {value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <Chip key={key} label={option.name} {...tagProps} />;
          })}
        </div>
      )}
      ListboxComponent={VideoAgreementTemplatesFinderList}
      // @ts-expect-error trick to pass items to ListboxComponent
      renderOption={(props, option, state) => [props, option, state.index]}
      onChange={(event, newValue) => onSelected(newValue)}
      renderInput={(params) => (
        <TextInput
          {...params}
          variant="outlined"
          fullWidth
          {...TextFieldProps}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <React.Fragment>
                <Icon icon="search_line" color="grey" />
              </React.Fragment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

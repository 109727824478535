import { gql } from '@apollo/client';

export const SEND_ONBOARDING_NOTIFICATION = gql`
  mutation sendOnboardingNotification(
    $input: SendOnboardingNotificationInput!
  ) {
    sendOnboardingNotification(input: $input) {
      __typename
      ... on UserNotFound {
        message
        esMessage
      }
      ... on UserAlreadyActive {
        message
        esMessage
      }
      ... on EmailAlreadyInUse {
        message
        esMessage
      }
      ... on NotificationTimeout {
        message
        esMessage
      }
      ... on SendOnboardingNotificationSuccess {
        message
        esMessage
      }
    }
  }
`;

export const GET_EMPLOYEE_INFO = gql`
  query GetEmployeeInfo($input: GetEmployeeInfoInput!) {
    getEmployeeInfo(input: $input) {
      __typename
      employeeInfo {
        __typename
        ... on EmployeeInfo {
          _id
          userId
          alta
          fullName
          lastNameP
          lastNameM
          names
          payrollId
          rfc
          curp
          workTitle
          currentStatus {
            name
            endDate
            startDate
          }
          currentLegalStatus {
            name
            startDate
            endDate
          }
          bajaDate
          createdAt
          workCenterId
          email
          phone
          profilePicture
          signedPercentage
          hasEverBeenActive
          hasPassword
          blocked
          groups
          currentAbsence {
            absenceId
            name
            reason
            start
            end
          }
          sentOnboardingNotification {
            dateSent
            sentBy {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
          }
          gender
          maritalStatus
          birthdate
          address {
            country
            zipCode
            state
            city
            colony
            street
            externalNumber
            internalNumber
          }
          socialSecurityNumber
          salary
          workDayType
          contractType
          employmentStartDate
          workCenterName
        }
        ... on UserNotFound {
          message
          esMessage
        }
      }
      employeeDocumentCounts {
        __typename
        ... on EmployeeDocumentCounts {
          totalDocuments
          totalSignableDocuments
          totalSignedDocuments
          totalSeenRequiredDocuments
          totalSeenDocuments
          totalUploadOnlyDocuments
          totalPendingSignableDocuments
          totalPendingSeenRequiredDocuments
          signedDocumentsPercent
          seenDocumentsPercent
          completedDocumentsPercent
        }
        ... on ResourceNotFound {
          message
          params
        }
      }
      employeeReceiptCounts {
        __typename
        ... on EmployeeReceiptCounts {
          totalReceipts
          totalSignedReceipts
          totalPendingSignReceipts
          signedReceiptsPercent
        }
        ... on ResourceNotFound {
          message
          params
        }
      }
      recordChecklistAssigned {
        __typename
        ... on RecordChecklistAssigned {
          _id
          name
          assignType
          assignedAt
        }
        ... on ResourceNotFound {
          message
          params
        }
        ... on RecordChecklistNotAssigned {
          companyId
          userId
        }
      }
      recordChecklistAssignedDocumentsCounts {
        __typename
        ... on RecordChecklistAssignedDocumentsCounts {
          totalDocuments
          totalDocumentsCompleted
          percentageCompleted
        }
        ... on ResourceNotFound {
          message
          params
        }
        ... on RecordChecklistNotAssigned {
          companyId
          userId
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_RECORD_CHECKLIST_COUNTS = gql`
  query GetRecordChecklistAssignedByEmployeeDocumentCounts(
    $employeeId: String!
  ) {
    getRecordChecklistAssignedByEmployee(employeeId: $employeeId) {
      recordChecklistAssignedDocumentsCounts {
        __typename
        ... on RecordChecklistAssignedDocumentsCounts {
          totalDocuments
          totalDocumentsCompleted
          percentageCompleted
        }
        ... on ResourceNotFound {
          message
          params
        }
        ... on RecordChecklistNotAssigned {
          companyId
          userId
        }
      }
    }
  }
`;
export const GET_EMPLOYEE_RECORD_CHECKLIST_DOCUMENTS = gql`
  query GetRecordChecklistAssignedByEmployeeDocuments($employeeId: String!) {
    getRecordChecklistAssignedByEmployee(employeeId: $employeeId) {
      recordChecklistAssignedDocuments {
        __typename
        ... on RecordChecklistAssignedDocuments {
          documents {
            _id
            title
            fileExtension
            categoryColor
            category
            subcategory
            uploaded
            onlyStorage
            canBeSigned
            signed
            signedAt
            seen
            seenAt
            createdAt
            status
            canRequestNewDoc
            history {
              action
              comment
              createdAt
              createdBy {
                _id
                names
                lastNameP
                lastNameM
              }
            }
          }
        }
        ... on ResourceNotFound {
          message
          params
        }
        ... on RecordChecklistNotAssigned {
          companyId
          userId
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_RECORD_CHECKLIST = gql`
  query GetRecordChecklistAssignedByEmployee($employeeId: String!) {
    getRecordChecklistAssignedByEmployee(employeeId: $employeeId) {
      recordChecklistAssigned {
        __typename
        ... on RecordChecklistAssigned {
          _id
          name
          assignType
          assignedAt
        }
        ... on ResourceNotFound {
          message
          params
        }
        ... on RecordChecklistNotAssigned {
          companyId
          userId
        }
      }
      recordChecklistAssignedDocumentsCounts {
        __typename
        ... on RecordChecklistAssignedDocumentsCounts {
          totalDocuments
          totalDocumentsCompleted
          percentageCompleted
        }
        ... on ResourceNotFound {
          message
          params
        }
        ... on RecordChecklistNotAssigned {
          companyId
          userId
        }
      }
      recordChecklistAssignedDocuments {
        __typename
        ... on RecordChecklistAssignedDocuments {
          documents {
            _id
            title
            fileExtension
            categoryColor
            category
            subcategory
            uploaded
            onlyStorage
            canBeSigned
            signed
            signedAt
            seen
            seenAt
            createdAt
            canRequestNewDoc
            history {
              action
              comment
              createdAt
              createdBy {
                _id
                names
                lastNameP
                lastNameM
              }
            }
          }
        }
        ... on ResourceNotFound {
          message
          params
        }
        ... on RecordChecklistNotAssigned {
          companyId
          userId
        }
      }
    }
  }
`;

export const GET_SIMPLE_EMPLOYEE_INFO = gql`
  query GetEmployeeInfo($input: GetEmployeeInfoInput!) {
    getEmployeeInfo(input: $input) {
      __typename
      employeeInfo {
        __typename
        ... on EmployeeInfo {
          _id
          userId
          alta
          fullName
          lastNameP
          lastNameM
          names
          payrollId
          rfc
          curp
          workTitle
          currentStatus {
            name
            endDate
            startDate
          }
          currentLegalStatus {
            name
            startDate
            endDate
          }
          bajaDate
          createdAt
          workCenterId
          email
          phone
          profilePicture
          signedPercentage
          hasEverBeenActive
          hasPassword
          blocked
          groups
          currentAbsence {
            absenceId
            name
            reason
            start
            end
          }
          sentOnboardingNotification {
            dateSent
            sentBy {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
          }
          gender
          maritalStatus
          birthdate
          address {
            country
            zipCode
            state
            city
            colony
            street
            externalNumber
            internalNumber
          }
          socialSecurityNumber
          salary
          workDayType
          contractType
          employmentStartDate
          workCenterName
        }
        ... on UserNotFound {
          message
          esMessage
        }
      }
    }
  }
`;

export const GET_ONBOARDING_LINK = gql`
  query GetOnboardingLink($input: GetOnboardingLinkInput!) {
    getOnboardingLink(input: $input) {
      ... on GetOnboardingLinkSuccess {
        onboardingLink
      }
      ... on UserNotFound {
        message
        esMessage
      }
      ... on UserAlreadyActive {
        message
        esMessage
      }
      ... on UserMustBeSifeType {
        message
      }
      ... on UserAlreadyHasPassword {
        message
      }
      ... on UserIsNotAdmin {
        message
      }
    }
  }
`;

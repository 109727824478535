import { SVGProps } from 'react';

export function VideoAgreementCameraIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="240"
      height="241"
      viewBox="0 0 240 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M195.205 75.564C188.496 71.8038 180.579 71.961 174.028 75.9948L165.007 81.5419C164.635 69.8772 155.063 60.5 143.33 60.5H56.511C44.5456 60.5 34.8164 70.2497 34.8164 82.2404V158.76C34.8164 170.744 44.5456 180.5 56.511 180.5H143.336C155.063 180.5 164.641 171.123 165.013 159.458L174.033 165.005C177.431 167.095 181.201 168.148 184.976 168.148C188.479 168.148 191.981 167.246 195.211 165.436C201.92 161.676 205.928 154.831 205.928 147.124V93.876C205.928 86.1694 201.92 79.3242 195.211 75.564H195.205ZM154.558 158.754C154.558 164.953 149.522 169.999 143.336 169.999H56.511C50.325 169.999 45.289 164.953 45.289 158.754V82.2404C45.289 76.0413 50.325 70.9948 56.511 70.9948H143.336C149.522 70.9948 154.558 76.0413 154.558 82.2404V158.76V158.754ZM195.455 147.118C195.455 150.971 193.451 154.394 190.1 156.274C186.742 158.154 182.787 158.073 179.511 156.059L165.03 147.153V93.8411L179.511 84.9354C182.787 82.9214 186.748 82.8399 190.1 84.72C193.457 86.6001 195.455 90.0227 195.455 93.876V147.118Z"
        fill="url(#paint0_linear_49_176629)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_49_176629"
          x1="52.8821"
          y1="46.4046"
          x2="131.845"
          y2="222.551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46F9" />
          <stop offset="1" stopColor="#2093FD" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import { useEffect, useRef, useState } from 'react';
import { IconButton } from '../../../../newComponents';
import {
  Video,
  VideoWrapper,
  VideoPlayerContainer,
  VideoActionWrapper,
  OptionButton,
} from './VideoAgreementRecorderResume.styles';

export const VideoPlayer = ({
  onClose,
  videoUrl,
}: {
  onClose: () => void;
  videoUrl: string;
}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => setIsPlaying(true);
  const handleEnded = () => setIsPlaying(false);
  const handlePause = () => setIsPlaying(false);

  useEffect(() => {
    const element = videoRef?.current;
    if (element) {
      element.addEventListener('play', handlePlay, false);
      element.addEventListener('ended', handleEnded, false);
      element.addEventListener('pause', handlePause, false);
      return () => {
        element.removeEventListener('play', handlePlay, false);
        element.removeEventListener('ended', handleEnded, false);
        element.removeEventListener('pause', handlePause, false);
      };
    }
  }, []);

  return (
    <VideoPlayerContainer>
      <VideoWrapper>
        <Video ref={videoRef} src={videoUrl} playsInline autoPlay />
        {isPlaying ? (
          <IconButton
            icon="pause_circle_line"
            onClick={() => videoRef.current.pause()}
            size="large"
            color="white"
            sx={{ position: 'absolute' }}
          />
        ) : (
          <IconButton
            icon="play_circle_line"
            onClick={() => videoRef.current.play()}
            size="large"
            color="white"
            sx={{ position: 'absolute' }}
          />
        )}
      </VideoWrapper>
      <VideoActionWrapper>
        <OptionButton
          color="white"
          icon="close_line"
          onClick={onClose}
          size="medium"
        />
      </VideoActionWrapper>
    </VideoPlayerContainer>
  );
};
